<template>
  <div
    class="flex px-3 py-2 hover:bg-medium-grey items-center justify-between w-full phone-block group"
  >
    <div class="flex items-center space-x-4">
      <div class="flex items-center space-x-2">
        <icon-base v-if="telephone" :icon="IconTelephone" />
        <div class="flex flex-col">
          <p class="text-sm">
            {{ maskPhoneNumber || $t("ACTIVITY.CONTACTS.NO_PHONE_NUMBER") }}
          </p>
          <p v-if="isDefault" class="text-sm">
            {{ $t("COMMON.DEFAULT") }}
          </p>
        </div>
      </div>
      <div v-if="isPrimary">
        <icon-base
          data-cy="primary-badge"
          :icon="IconPrimaryIndicator"
          class="text-blue-500"
        />
      </div>
    </div>
    <div class="flex items-center justify-self-end">
      <icon-base
        v-if="isActive"
        icon="check"
        class="text-primary group-hover:hidden"
        width="18"
        height="18"
      />
    </div>
    <copy-to-clipboard-button
      v-if="telephone"
      class="copy-button"
      :value="telephone"
      @click.stop
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import IconTelephone from "@/components/icons/IconTelephone.vue";
import IconPrimaryIndicator from "@/components/icons/IconPrimaryIndicator.vue";
import CopyToClipboardButton from "@/components/CopyToClipboardButton.vue";
import type { PropType } from "vue";

defineEmits<{
  (eventName: "telephone:selected", telephone: string): void;
}>();

const props = defineProps({
  telephone: {
    type: String as PropType<string | null>,
    default: ""
  },
  isPrimary: {
    type: Boolean,
    default: false
  },
  isActive: {
    type: Boolean,
    default: false
  },
  isDefault: {
    type: Boolean,
    default: false
  }
});

const maskPhoneNumber = computed(() => {
  if (!props.telephone) {
    return "";
  }
  let digitsOnly = props.telephone.replace(/\D/g, "");
  if (digitsOnly.startsWith("1")) {
    digitsOnly = "+1" + digitsOnly.slice(1);
  }
  const lastThreeDigits = digitsOnly.slice(-3);
  const maskedLastThreeDigits = `*** *** *${lastThreeDigits}`;
  return digitsOnly.startsWith("+")
    ? `${digitsOnly.slice(0, 2)} ${maskedLastThreeDigits}`
    : maskedLastThreeDigits;
});
</script>
<style scoped>
.copy-button {
  @apply hidden opacity-0 hover:bg-gray-200 p-1;
}

.phone-block:hover .copy-button {
  @apply block opacity-100;
}
</style>
